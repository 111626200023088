<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-date-picker
          size="small"
          class="vg_ml_16 topDateRangeSearch"
          v-model="dateRange"
          type="daterange"
          range-separator="至"
          start-placeholder="实际付款开始日期"
          end-placeholder="实际付款结束日期"
        >
        </el-date-picker>
        <el-date-picker
          size="small"
          class="vg_ml_16 topDateRangeSearch"
          v-model="dateRange2"
          type="daterange"
          range-separator="至"
          start-placeholder="录入开始日期"
          end-placeholder="录入结束日期"
        >
        </el-date-picker>
        <el-button size="small" type="primary" icon="el-icon-search" @click="getStatsList()" class="vg_ml_16">查询</el-button>
        <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()" icon="el-icon-refresh-right">刷新</el-button>
      </div>
      <DynamicUTable
        ref="searchTable"
        :table-data="tableData"
        :need-search="true"
        :columns="ppinTableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        :show-summary="true"
        :summariesColumns="['ppay_part_subtotal', 'ppay_part_paid', 'ppay_part_unpaid']"
        @selection-change="handleSelectionChange"
        @getTableData="getStatsList"
      >
      </DynamicUTable>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="70%">
        <el-button @click="printC">打印</el-button>
        <div id="printMe" class="printc">
          <table border="1px" width="100%" cellpadding="0" cellspacing="0" class="vd_table printd">
            <tr>
              <!-- <th class="vd_th">素材名称</th> -->
              <th class="vd_th">系数</th>
              <th class="vd_th">损耗用量</th>
              <th class="vd_th vd_right">损耗金额</th>
            </tr>
            <tr v-for="item in tableData" :key="item.ppin_id">
              <!-- <td class="vd_td_o">损耗比率</td> -->
              <td class="vd_td_t">
                {{ item.tran_no }}
              </td>
              <td class="vd_td_t">
                {{ item.podr_no }}
              </td>
              <td class="vd_td_t vd_right">
                {{ item.prod_cust_no }}
              </td>
            </tr>
            <tr v-for="item in tableData" :key="item.ppin_id" style="page-break-after: always">
              <!-- <td class="vd_td_o">损耗比率</td> -->
              <td class="vd_td_t">
                {{ item.tran_no }}
              </td>
              <td class="vd_td_t">
                {{ item.podr_no }}
              </td>
              <td class="vd_td_t vd_right">
                {{ item.prod_cust_no }}
              </td>
            </tr>
            <tr v-for="item in tableData" :key="item.ppin_id">
              <!-- <td class="vd_td_o">损耗比率</td> -->
              <td class="vd_td_t">
                {{ item.tran_no }}
              </td>
              <td class="vd_td_t">
                {{ item.podr_no }}
              </td>
              <td class="vd_td_t vd_right">
                {{ item.prod_cust_no }}
              </td>
            </tr>
            <tr v-for="item in tableData" :key="item.ppin_id">
              <!-- <td class="vd_td_o">损耗比率</td> -->
              <td class="vd_td_t">
                {{ item.tran_no }}
              </td>
              <td class="vd_td_t">
                {{ item.podr_no }}
              </td>
              <td class="vd_td_t vd_right">
                {{ item.prod_cust_no }}
              </td>
            </tr>
            <tr v-for="item in tableData" :key="item.ppin_id">
              <!-- <td class="vd_td_o">损耗比率</td> -->
              <td class="vd_td_t">
                {{ item.tran_no }}
              </td>
              <td class="vd_td_t">
                {{ item.podr_no }}
              </td>
              <td class="vd_td_t vd_right">
                {{ item.prod_cust_no }}
              </td>
            </tr>
            <tr v-for="item in tableData" :key="item.ppin_id">
              <!-- <td class="vd_td_o">损耗比率</td> -->
              <td class="vd_td_t">
                {{ item.tran_no }}
              </td>
              <td class="vd_td_t">
                {{ item.podr_no }}
              </td>
              <td class="vd_td_t vd_right">
                {{ item.prod_cust_no }}
              </td>
            </tr>
            <tr v-for="item in tableData" :key="item.ppin_id">
              <!-- <td class="vd_td_o">损耗比率</td> -->
              <td class="vd_td_t">
                {{ item.tran_no }}
              </td>
              <td class="vd_td_t">
                {{ item.podr_no }}
              </td>
              <td class="vd_td_t vd_right">
                {{ item.prod_cust_no }}
              </td>
            </tr>
            <tr v-for="item in tableData" :key="item.ppin_id">
              <!-- <td class="vd_td_o">损耗比率</td> -->
              <td class="vd_td_t">
                {{ item.tran_no }}
              </td>
              <td class="vd_td_t">
                {{ item.podr_no }}
              </td>
              <td class="vd_td_t vd_right">
                {{ item.prod_cust_no }}
              </td>
            </tr>
          </table>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch } from '@api/request';
import { stisAPI } from '@api/modules/stis';
import pubPagination from '@/components/common/pubPagination';
import printJS from 'print-js';
import { ppinTableProperties } from '@/views/SettleManagement/StisManage/stis';
import SearchTable from '@/components/table/SearchTableV2.vue';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'StatList',
  components: {
    DynamicUTable,
    SearchTable,
    pubPagination
  },
  data() {
    return {
      dateRange: [],
      dateRange2: [],
      ppinTableProperties: ppinTableProperties,
      tableData: [],
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      dialogVisible: false,
      printObj: {
        id: 'printMe', // 这里是要打印元素的ID
        popTitle: '&nbsp', // 打印的标题
        extraCss: 'height:auto;', // 打印可引入外部的一个 css 文件
        extraHead: '', // 打印头部文字
        preview: true
      }
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/stat_edit' || from.path === '/stat_add') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getStatsList();
    },
    getStatsList() {
      this.loadFlag = true;
      let searchForm = cloneDeep(this.$refs.searchTable.searchForm);
      let [startTime, endTime] = this.dateRange || [];
      searchForm.start_time = startTime ? startTime / 1000 : null;
      searchForm.end_time = endTime ? endTime / 1000 : null;
      let [startTime2, endTime2] = this.dateRange2 || [];
      searchForm.start_time2 = startTime2 ? startTime2 / 1000 : null;
      searchForm.end_time2 = endTime2 ? endTime2 / 1000 : null;
      searchForm.cptt_name_list = searchForm.cptt_name_list?.toString();
      getNoCatch(stisAPI.getPpins, searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.ppinTableProperties.find(({ prop }) => prop === 'cptt_name_list').options = Array.from(data.cptt_name, val => {
          return { value: val, label: val };
        });
        this.btn = data.btn;
        this.loadFlag = false;
      });
    },
    // 刷新
    buttonRefresh() {
      this.dateRange = [];
      this.dateRange2 = [];
      this.$refs.searchTable.resetFields();
      this.getStatsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 打印
    printClick() {
      this.dialogVisible = true;
    },
    printC() {
      const style = '@page {margin:20px 10px;};'; //打印时去掉眉页眉尾
      //打印为什么要去掉眉页眉尾？因为眉页title时打印当前页面的title，相当于是获取html中title标签里面的内容，但是比如我打印的内容只是一个弹框里面的内容，是没有title的，这时候就会出现undefined，为了避免出现这种情况，就可以隐藏眉页眉尾
      printJS({
        printable: 'printMe', // 标签元素id
        type: 'html',
        header: '',
        targetStyles: ['*'],
        style
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}
.vd_col {
  color: #f00;
}

table .vd_td_t {
  // width:40%;
  height: 30px;
  border-bottom: #e9e9e9 1px solid;
  border-right: #e9e9e9 1px solid;
  border-left: none;
  border-top: #e9e9e9 1px solid;
}
table .vd_td_o {
  width: 20%;
  padding-left: 5px;
  color: #606266;
  border-bottom: #e9e9e9 1px solid;
  border-right: #e9e9e9 1px solid;
  border-left: #e9e9e9 1px solid;
  border-top: none;
}
.vd_td_o:nth-of-type(1) {
  border-top: #e9e9e9 1px solid;
}
table {
  // border: #f3f3f3 1px solid;
  border: none;
  box-sizing: border-box;
  margin-top: 30px;
}
.vd_th {
  // width: 50px;
  max-width: 50px;
  text-align: left;
  height: 30px;
  border-top: #e9e9e9 1px solid;
  background-color: #f5f7fa;
  color: #909399;
  border: none;
}
table tr {
  page-break-inside: avoid;
  page-break-after: auto;
}
.printd {
  page-break-after: always;
}
.printc {
  padding: 0 20px;
  box-sizing: border-box;
}
</style>
