import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

// async function fetchData() {
//   let cpttList = await getCptt();
//   let indices = {};
//   ppinTableProperties.forEach(({ prop }, index) => {
//     if (['cptt_name_list'].includes(prop)) indices[prop] = index;
//   });
//   return {
//     [`ppinTableProperties[${indices['cptt_name_list']}].options`]: cpttList
//   };
// }
export const soinTableProperties = [
  store.state.selection,
  store.state.index,
  {
    label: '采购发票日期',
    prop: 'tran_ivdate',
    itemType: 'input',
    input: false,
    sortable: true,
    widthAuto: false,
    formatter: val => getDateNoTime(val, true)
  },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: false, sortable: false },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: true, labelWidth: '120px', overflowTooltip: true },
  { label: '币种', prop: 'cust_currency', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '应收金额', prop: 'soll_subrece', itemType: 'input', input: false, sortable: true, labelWidth: '120px' },
  { label: '实收金额', prop: 'soll_reced_total', itemType: 'input', input: false, sortable: true, labelWidth: '120px' },
  { label: '银行费用', prop: 'soll_rece_char', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '未收金额', prop: 'soll_unreced', itemType: 'input', input: false, sortable: true, labelWidth: '120px' },
  { label: '账目类型', prop: 'tran_status_type', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '应收日期', prop: 'tran_ppdate', itemType: 'date', input: false, sortable: true, formatter: val => getDateNoTime(val, true) },
  { label: '经办人', prop: 'tran_stff_name', itemType: 'input', input: true, sortable: false, labelWidth: '120px' },
  { label: '收款抬头', prop: 'cptt_bname', itemType: 'input', input: true, sortable: false, labelWidth: '120px', overflowTooltip: true },
  // { label: '付款单位', prop: 'cptt_aname', itemType: 'input', input: false, sortable: false, labelWidth: '120px', overflowTooltip: true },
  { label: '汇款抬头', prop: 'cptt_aname', itemType: 'input', input: true, sortable: false, labelWidth: '120px', overflowTooltip: true },
  { label: '逾期天数', prop: 'elapse_date', itemType: 'input', input: false, sortable: false },
  { label: '逾期类型', prop: 'elapse_type', itemType: 'input', input: false, sortable: false },
  {
    label: '收款状态',
    prop: 'payment_status',
    itemType: 'select',
    options: [
      { value: 0, label: '未收款/部分收款' },

      { value: 3, label: '结束收款' }
    ],
    fixed: 'right',
    formatter: val => formatStatus(val),
    input: true,
    sortable: false
  }
];
export const hoinTableProperties = [
  store.state.selection,
  store.state.index,
  {
    label: '发票日期',
    prop: 'tran_ivdate',
    itemType: 'input',
    input: false,
    sortable: true,
    widthAuto: false,
    formatter: val => getDateNoTime(val, true)
  },
  // {
  //   label: '离港日期',
  //   prop: 'tran_dpdate',
  //   itemType: 'input',
  //   input: false,
  //   sortable: true,
  //   widthAuto: false,
  //   formatter: val => getDateNoTime(val, true)
  // },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false },
  { label: '销售合同号', prop: 'scon_no', itemType: 'input', input: true, sortable: false },
  { label: '客户订单号', prop: 'scon_cust_no', itemType: 'input', input: false, sortable: false },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: true },
  { label: '币种', prop: 'cust_currency', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '应收金额', prop: 'holl_subrece', itemType: 'input', input: false, sortable: true },
  { label: '实收金额', prop: 'holl_netrece', itemType: 'input', input: false, sortable: true },
  { label: '银行费用', prop: 'holl_rece_char', itemType: 'input', input: false, sortable: false },
  { label: '未收金额', prop: 'holl_unreced', itemType: 'input', input: false, sortable: true },
  { label: '账目类型', prop: 'tran_status', itemType: 'input', input: false, sortable: false },
  { label: '应收日期', prop: 'tran_ppdate', itemType: 'date', input: false, sortable: true, formatter: val => getDateNoTime(val, true) },
  // {
  //   label: '账目类型',
  //   prop: 'type',
  //   itemType: 'select',
  //   options: [
  //     { value: 1, label: '货款' },
  //     { value: 2, label: '费用' }
  //   ],
  //   formatter: val => (val === 1 ? '货款' : '费用'),
  //   input: true,
  //   sortable: false,
  //   labelWidth: '120px'
  // },
  { label: '经办人', prop: 'stff_name', itemType: 'input', input: true, sortable: false, labelWidth: '120px' },
  { label: '收款抬头', prop: 'cptt_bname', itemType: 'input', input: true, sortable: false },
  { label: '汇款抬头', prop: 'cptt_aname', itemType: 'input', input: true, sortable: false },
  // { label: '付款单位', prop: 'cptt_aname', itemType: 'input', input: false, sortable: false },
  { label: '逾期天数', prop: 'days_overdue', itemType: 'input', input: false, sortable: false },
  {
    label: '逾期类型',
    prop: 'overdue_type',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '收款状态',
    prop: 'payment_status',
    itemType: 'select',
    options: [
      { value: 0, label: '未收款/部分收款' },

      { value: 2, label: '结束收款' }
    ],
    fixed: 'right',
    formatter: val => formatStatusForHk(val),
    input: true,
    sortable: false
  }
];
export const formatStatus = val => {
  switch (val) {
    case 0:
      return '未收款/部分收款';
    case 2:
      return '已收款';
    case 3:
      return '结束收款';
  }
};

export const formatStatusForHk = val => {
  switch (val) {
    case 0:
      return '未收款/部分收款';
    case 1:
      return '已收款';
    case 2:
      return '结束收款';
  }
};

export const ppinTableProperties = [
  {
    label: '单据来源',
    prop: 'stat_no',
    itemType: 'select',
    options: [
      { value: '采购发票', label: '采购发票' },
      { value: '其他采购发票', label: '其他采购发票' }
    ],
    input: true,
    sortable: false,
    labelWidth: '120px'
  },
  { label: '源单号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, labelWidth: '120px' },
  {
    label: '付款抬头',
    prop: 'cptt_name_list',
    itemType: 'select',
    options: [],
    filterable: true,
    collapseTags: true,
    multiple: true,
    input: true,
    sortable: false,
    labelWidth: '120px',
    needOtherColumn: true,
    formatter: row => row.cptt_name
  },
  { label: '采购合同号', prop: 'podr_no', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '客户货号', prop: 'prod_cust_no', itemType: 'input', input: true, sortable: false, labelWidth: '120px' },
  { label: '收款单位', prop: 'supp_abbr', itemType: 'input', input: true, sortable: false, labelWidth: '120px' },
  { label: '币种', prop: 'cust_currency', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '应付金额', prop: 'ppay_part_subtotal', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '已付金额', prop: 'ppay_part_paid', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  { label: '未付金额', prop: 'ppay_part_unpaid', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  {
    label: '应付日期',
    prop: 'ppay_rpdate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: '120px',
    formatter: val => getDateNoTime(val, true)
  },
  { label: '账目类型', prop: 'stat_total', itemType: 'input', input: false, sortable: false, labelWidth: '120px', formatter: () => '货款' },
  { label: '经办人', prop: 'tran_stff_name', itemType: 'input', input: false, sortable: false, labelWidth: '120px' },
  store.state.stff_name,
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    labelWidth: '120px',
    valueFormat: 'timestamp',
    format: 'yyyy-MM-dd',
    formatter: val => getDateNoTime(val, true)
  }
];
// fetchData().then(data => {
//   for (const key in data) {
//     eval(key + '=data[key]');
//   }
// });
